<script setup lang="ts">
  import { useSnackBarStore } from "~/stores/SnackBarStore";
  import { useErrorStore } from "~/stores/ErrorStore";
  import { useAppUpdate } from "~/composables/useAppUpdate";
  import { useGTM } from "~/composables/useGTM";
  import { useWebPush } from "~/composables/useWebPush";
  import { usePushQuay } from "~/composables/usePushQuay";
  import { checkAppInBrowser } from "~/utils/Display";
  import AtomsDialogConfirmDialog from "~/components/Atoms/Dialog/ConfirmDialog.vue";
  import confirmDialogInstance from "~/composables/useConfirmDialog";

  // スナックバーstore
  const snackBarStore = useSnackBarStore();

  const confirmDialogRef = ref<InstanceType<typeof AtomsDialogConfirmDialog> | null>(null);

  // エラー画面
  const errorStore = useErrorStore();
  errorStore.reset();

  // AppUpdate
  const appUpdate = useAppUpdate();

  // GTM
  const gtm = useGTM();

  // webpush
  const webPush = useWebPush();

  // PushQuay
  const pushQuay = usePushQuay();

  const isOpenAppInBrowser = ref(false);

  onMounted(() => {
    isOpenAppInBrowser.value = checkAppInBrowser() != null;
    webPush.onMounted();
    pushQuay.onMounted();
    gtm.onMounted();
    appUpdate.onMounted();

    if (confirmDialogRef.value) {
      confirmDialogInstance.setDialogRef(confirmDialogRef.value);
    }
  });

  onUnmounted(() => {
    appUpdate.onUnmounted();
    gtm.onUnmounted();
    webPush.onUnmounted();
    pushQuay.onUnmounted();
  });
</script>

<template>
  <v-app>
    <slot></slot>
    <OrganismsAppUpdate />
    <MoleculesErrorDialog
      v-model:is-open="errorStore.isOpen"
      :message="errorStore.dialogMessage"
      :button-title="errorStore.buttonTitle"
      @click-close="errorStore.close"
    />

    <AtomsAlertSnackBar
      v-model:show="snackBarStore.show"
      :color="snackBarStore.color"
      :timeout="snackBarStore.timeOutMilliSecond"
    >
      {{ snackBarStore.message }}
    </AtomsAlertSnackBar>
    <MoleculesOpenBrowserDialog :is-open="isOpenAppInBrowser" />
  </v-app>

  <AtomsDialogConfirmDialog ref="confirmDialogRef" />
</template>
