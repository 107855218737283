import { useLoginStore } from "~/stores/LoginStore";
import { usePushQuayStore } from "~/stores/PushQuayStore";

/**
 * PushQuay 用処理 (useWebPush を参考に実装)
 */
export const usePushQuay = () => {
  // トークン送信間隔。1時間ごとに送信する
  const TOKEN_SEND_INTERVAL = 1000 * 60 * 60;

  const pushQuayStore = usePushQuayStore();
  const loginStore = useLoginStore();

  // トークン登録用タイマー
  let sendTokenIntervalId: any = null;

  // ログイン状態を調べて、ログインしている場合はトークンをAPIに送信する
  // トークンを定期的に送信しないと、バックエンド側で14日間でトークンが無効になるため
  const sendToken = async () => {
    if (!pushQuayStore.isPushQuaySupported()) {
      return;
    }
    try {
      // PushQuay 通知登録時にローカル保存したトークンにて定期更新をかける
      if (loginStore.isLogin() && loginStore.pushQuayToken) {
        // 内部で一定時間内は送信しないようにしているのでここで頻繁によびだしても問題ない
        await pushQuayStore.registerPushQuayToken(loginStore.pushQuayToken);
      }
    } catch (_e) {}
  };

  const onVisibilityChange = () => {
    // ページがフォーカスされた場合はトークンを送信する。
    if (document.visibilityState === "visible") {
      sendToken();
    }
  };

  const onMounted = () => {
    // 一定時間ごとにトークンを送信する
    sendTokenIntervalId = setInterval(() => {
      sendToken();
    }, TOKEN_SEND_INTERVAL);

    // visibilitychangeイベントを監視して、フォーカスされた場合にトークンを送信する
    document.addEventListener("visibilitychange", onVisibilityChange);
  };

  const onUnmounted = () => {
    if (sendTokenIntervalId) {
      clearInterval(sendTokenIntervalId);
      sendTokenIntervalId = null;
    }

    // visibilitychangeイベントを削除
    document.removeEventListener("visibilitychange", onVisibilityChange);
  };

  return {
    onMounted,
    onUnmounted,
  };
};
