<script setup lang="ts">
  /**
   * 確認ダイアログ
   * 使いたいコンポーネントにconst { $confirmDialog } = useNuxtApp();で$confirmDialogを取得する
   * ダイアログを開く場合は、必要な操作の中に、以下のように記述する
   * $confirmDialog.openDialog({
   *   message: `削除しますか？`,
   *   onConfirm: () => {}, // はいを押した時の処理
   * });
   */

  export interface Props {
    message: string;
    subMessage?: string;
    yesTitle?: string;
    yesColor?: string;
    noTitle?: string;
    subMessageDirection?: "left" | "center" | "right";
    hideNoButton?: boolean;
    isTest?: boolean; // テスト時のみ true にする。でないと v-dialog を find できないため

    onConfirm?: () => boolean;
    onCancel?: () => boolean;
  }

  const isOpen = ref(false);

  const options = ref<Props>({
    message: "",
    subMessage: undefined,
    yesTitle: "はい",
    yesColor: "main-01",
    noTitle: "いいえ",
    subMessageDirection: "center",
  });

  const open = (props: Props) => {
    isOpen.value = true;
    options.value = { ...options.value, ...props };
  };

  const close = () => {
    if (options.value.onCancel) {
      const res = options.value.onCancel();
      if (res === false) return;
    }
    isOpen.value = false;
  };

  const confirm = () => {
    if (options.value.onConfirm) {
      const res = options.value.onConfirm();
      if (res === false) return;
    }
    isOpen.value = false;
  };

  defineExpose({ open, close });
</script>

<template>
  <v-dialog v-model="isOpen" scrim="black" :attach="!!options.isTest">
    <v-sheet class="dialog py-8 px-10 rounded" color="monochrome-04" align="center">
      <div class="text-dialog-title mb-4">{{ options.message }}</div>
      <div v-if="options.subMessage" :class="`text-dialog-description text-${options.subMessageDirection}`">
        {{ options.subMessage }}
      </div>

      <div class="d-flex justify-center mt-4">
        <AtomsDialogButton
          v-if="!options.hideNoButton"
          color="monochrome-02"
          class="mt-4"
          data-testid="dialog-no-button"
          @click="close"
        >
          {{ options.noTitle }}
        </AtomsDialogButton>
        <AtomsDialogButton :color="options.yesColor" class="mt-4" data-testid="dialog-yes-button" @click="confirm">
          {{ options.yesTitle }}
        </AtomsDialogButton>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<style lang="scss" scoped>
  .dialog {
    min-height: 146px;
    min-width: 345px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
</style>
